<template>
  <div class="teams">
    <div class="type-tabs">
      <div
        class="tab"
        :class="{ active: activeTab === TYPES.first }"
        @click="changeType(TYPES.first)"
      >
        直推粉丝
      </div>
      <div
        v-if="userInfo.vip_level === 3"
        class="tab"
        :class="{ active: activeTab === TYPES.second }"
        @click="changeType(TYPES.second)"
      >
        间推粉丝
      </div>
    </div>
    <div v-if="activeTab === TYPES.first" class="teams-list">
      <div class="list-header">
        <div style="width: 33%">会员名</div>
        <div style="width: 33%">手机号码</div>
        <div style="width: 33%">会员等级</div>
      </div>
      <div v-loading="loading" class="list-content">
        <div v-for="item in resultList" :key="item.id" class="list-item">
          <div style="width: 33%">{{ item.real_name }}</div>
          <div style="width: 33%">{{ item.mobile }}</div>
          <div style="width: 33%">
            {{ userVip(item.vip_level).levelname || "普通用户" }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="teams-list">
      <div class="list-header">
        <div style="width: 25%">会员名</div>
        <div style="width: 25%">手机号码</div>
        <div style="width: 25%">直接上级</div>
        <div style="width: 25%">会员等级</div>
      </div>
      <div v-loading="loading" class="list-content">
        <div v-for="item in resultList" :key="item.id" class="list-item">
          <div style="width: 25%">{{ item.real_name }}</div>
          <div style="width: 25%">{{ item.mobile }}</div>
          <div style="width: 25%">{{ item.from_user_name }}</div>
          <div style="width: 25%">
            {{ userVip(item.vip_level).levelname || "普通用户" }}
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      class="pager"
      background
      layout="prev, pager, next"
      :page-count="maxPage"
      @prev-click="fetchList"
      @next-click="fetchList"
      @current-change="fetchList"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Pagination } from "element-ui";

import apis from "@/apis/index";

const TYPES = {
  first: "first",
  second: "second"
};

// const mockData = new Array(30).fill({}).map((item, index) => ({
//   ...item,
//   id: index + 1,
//   real_name: "名字1",
//   mobile: "18573309100",
//   vip_level: [0, 1][index % 2]
// }));

export default {
  name: "Teams",
  components: {
    "el-pagination": Pagination
  },
  data() {
    return {
      TYPES,
      page: 1,
      maxPage: 0,
      loading: false,
      resultList: [],
      activeTab: TYPES.first,
      currApi: apis.userTeams
    };
  },
  computed: {
    ...mapState(["userInfo", "vipIndex"])
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    userVip(level) {
      return this.vipIndex.find(item => item.id === level) || {};
    },
    async fetchList(page) {
      this.page = page;
      this.loading = true;
      try {
        const {
          data: { user_list, max_page }
        } = await this.currApi({ page: this.page });
        this.maxPage = max_page;
        this.resultList = user_list;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    changeType(type) {
      this.activeTab = type;
      this.currApi =
        type === TYPES.first ? apis.userTeams : apis.secondUserTeams;
      this.fetchList(1);
    }
  }
};
</script>

<style lang="scss">
.van-list__loading {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.teams {
  margin: 20px;
  padding: 20px;
  background: #f7f7f7;
  border-radius: 4px;
  box-sizing: border-box;
  height: calc(100% - 130px);
  display: flex;
  flex-flow: column nowrap;

  .type-tabs {
    border-bottom: 1px solid #CA0A15;
    display: flex;
    margin-top: 15px;

    .tab {
      position: relative;
      width: 87px;
      height: 30px;
      background: rgba(139, 66, 51, 0.7);
      border-radius: 4px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 3px;
      margin-right: 10px;
      font-size: 14px;
      color: #8a574d;
      cursor: pointer;

      &.active {
        opacity: 1;
        color: #ffffff;
        background-color: #CA0A15;

        &::after {
          border-left: 5px solid #CA0A15;
          border-bottom: 15px solid #CA0A15;
        }
      }

      &::after {
        content: "";
        display: block;
        // height: 30px;
        // width: 6px;
        border-right: 5px solid transparent;
        border-top: 15px solid transparent;
        border-left: 5px solid rgba(139, 66, 51, 0.7);
        border-bottom: 15px solid rgba(139, 66, 51, 0.7);
        position: absolute;
        right: -10px;
        top: 0;
      }
    }
  }

  .teams-list {
    height: calc(100% - 46px);
    padding-top: 20px;

    .list-header {
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      color: #333333;
      font-size: 14px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 20px;
    }

    .list-content {
      padding: 0 20px;
      display: flex;
      flex-flow: column nowrap;
      overflow: auto;
      height: calc(100% - 60px);
      // flex: none;

      .list-item {
        flex: none;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 50px;
        font-size: 12px;
        color: #666666;
        border-bottom: 1px solid #f0f0f0;

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}

.pager {
  position: relative;
  top: 67px;
  display: flex;
  justify-content: center;
}
</style>
